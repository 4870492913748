<template lang="pug">
.profile-auth
  .profile-auth__authContent
    .profile-auth__authContent__header
      FcwRouterLink(
        :location="homepage"
        hide-arrow
      )
        FcwClientLogo
    .profile-auth__loginContainer
      NuxtPage
    .profile-auth__bottomContent
      .profile-auth__bottomContent__links
        FcwRouterLink(
          :location="{ name: 'terms-and-conditions' }"
          target="_blank"
          hide-arrow
        )
          span {{ t('common__terms_and_conditions') }}
        FcwRouterLink(
          :location="{ name: 'legal-notice' }"
          target="_blank"
          hide-arrow
        )
          span {{ t('common__legal_notice') }}
      .profile-auth__bottomContent__locale
        FcwLocaleSelect(display-mode="name")
  .profile-auth__carousel 
    .profile-auth__carouselSwitch(v-if="isFilterVisible")
      FSelect(
        v-if="isSmAndDown"
        v-model="selectedServiceType"
        :options="filterOptions"
        hide-hint
      )
      FcwButtonGroup(
        v-else
        v-model="selectedServiceType"
        :options="filterOptions"
      )
    FcwMobileAppsCarousel(
      v-model="selectedServiceType"
      :carousel-items="currentCarouselItems"
    )

  FcwAppStoresFallback.profile-auth__mobileFallback
</template>

<style lang="stylus">
.profile-auth
  background-color var(--color--neutral--dark-4)
  height 100vh
  width 100%
  display flex

.profile-auth__carousel
  position relative
  width 100%

.profile-auth__carouselSwitch
  position absolute
  display flex
  align-items center
  left 50%
  translate -50%
  top rem(24)
  z-index 1

  .FcwButtonGroup
    flex-shrink 0

.profile-auth__authContent
  position relative
  background-color var(--color--neutral--light-5)
  border-radius 0 rem(48) rem(48) 0
  padding rem(24) rem(56)
  display flex
  height 100%
  flex-direction column
  justify-content center
  align-items center
  width 'min(40vw, %s)' % rem(640)
  flex-shrink 0

  +media-down('sm')
    display none

.profile-auth__authContent__header
  position absolute
  top 0
  left 0
  padding rem(16) rem(24)
  align-self flex-start
  display flex
  justify-content space-between
  align-items center

  h4
    max-width rem(727)

  +media-down('sm')
    display none

.profile-auth__loginContainer
  max-width rem(476)
  width 100%
  display flex
  flex-direction column
  justify-content center
  align-items center

.profile-auth__bottomContent
  display flex
  justify-content space-between
  flex-wrap wrap
  align-items center
  position absolute
  white-space nowrap
  left rem(40)
  right rem(40)
  bottom rem(24)
  gap rem(16)
  align-content center

  +media-down('md')
    justify-content center

  +media-down('sm')
    display none

.profile-auth__bottomContent__links
  display flex
  flex-wrap wrap
  gap rem(16)

  +media-down('sm')
    color var(--color--neutral--light-5)

.profile-auth__mobileFallback
  position absolute
  top 0
  left 0
  z-index 10
  width 100%
  height 100%
  opacity 0
  overflow auto
  pointer-events none

  +media-down('sm')
    opacity 1
    pointer-events all
</style>

<script setup lang="ts">
const { t } = useClientI18n();
const clientStore = useClientStore();
const { homepage } = storeToRefs(clientStore);

const route = useRoute();
const { isSmAndDown } = useFBreakpoints();

onMounted(() => {
  if (route.query.redirect_to) {
    useAlert({
      type: 'warning',
      icon: 'exclamationTriangle',
      text: t('auth__alert__required_auth'),
      duration: 5000,
    });
  }
});

const {
  isFilterVisible,
  filterOptions,
  currentCarouselItems,
  selectedServiceType,
} = useMobileAppsOverview();
</script>
